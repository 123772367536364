import * as d3 from 'd3';
import React, { forwardRef,memo, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import type { FlowData } from '../../../components/FlowData';
import { ChartOptions, RangeSlider } from '../../../components/plots/ChartOptions';
import { Axes, PlotTitle } from '../../../components/plots/PlotComponents';
import { 
  addQuadrantSubPlot, QuadrantInfo, updateQuadrantInfo
} from '../../../components/plots/Quadrants';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import { ChannelDropdown } from '../channels/Channels';
import type { ClusterInfo } from "../Clusters";
import {
FlowPlotItem,
getFlowPlotItemById,   replaceItemInFlowPlotListTree, updateFlowPlotList} from './FlowPlotItem';
import type {
  ColorScaleInfo} from "./FlowPlots";
import {
  checkIfShouldAddQuadrant, CLUSTER_COLORS, createXScale, createYScale, drawPoints
} from "./FlowPlots";

const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);


/**
 * The data types for the ClusterFlowPlot component.
 *
 * @interface ClusterFlowPlotProps
 */
interface ClusterFlowPlotProps {
  /** The object containing the data to be plotted. */
  flowPlotItem: FlowPlotItem;
  /** A list of all the data for the different plots. */
  flowPlotList: Array<FlowPlotItem>;
  /** The setState method for flowPlotList. */
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  /** Determines which type of scatterplot to create. */
  scatterplotType: "umap" | "cluster" | "density";
  /** The object containing the .fcs file data. */
  flowData: FlowData;
  clusterInfo: ClusterInfo;
  dataIsLoading: boolean;
}

export const ClusterFlowPlot = memo(forwardRef(function(
  { flowPlotItem, flowPlotList, setFlowPlotList, scatterplotType, flowData,
    clusterInfo, dataIsLoading }: ClusterFlowPlotProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const xScale = createXScale(flowPlotItem.x, flowPlotItem.boundsWidth);
  const yScale = createYScale(flowPlotItem.y, flowPlotItem.boundsHeight);
  const [topLayer, setTopLayer] = useState(0);
  const [count, setCount] = useState(0);
  let newFlowPlotItem = new FlowPlotItem({});

  if (ref != null && typeof ref !== "function") {
    newFlowPlotItem = getFlowPlotItemById(flowPlotItem.id, ref.current!)[1];
    logger.info(
      "ClusterFlowPlot",
      "FlowPlot",
      flowPlotItem.id,
      "flowPlotListRef",
      ref.current!
    )();
  }

  logger.info(
    "ClusterFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "clusterInfo",
    clusterInfo
  )();

  const [quadrantInfo, setQuadrantInfo] = useState<QuadrantInfo>(
    () => {
      return newFlowPlotItem.quadrantInfo === undefined
        ? new QuadrantInfo({
          boundsHeight: flowPlotItem.boundsHeight,
          boundsWidth: flowPlotItem.boundsWidth,
          margin: flowPlotItem.margin
        })
        : newFlowPlotItem.quadrantInfo
    }
  );

  logger.info(
    "ClusterFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "quadrantInfo",
    quadrantInfo
  )();

  logger.info(
    "ClusterFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "newFlowPlotItem",
    newFlowPlotItem
  )();


  useEffect(() => {
    logger.info(
      "ClusterFlowPlot.useEffect => mount",
      "FlowPlot",
      flowPlotItem.id,
      "count: ",
      count
    )();

    if (canvasRef.current && flowPlotItem.x.length > 1 && count === 0 && !dataIsLoading) {
      logger.info(
        "ClusterFlowPlot.useEffect => mount",
        "FlowPlot",
        flowPlotItem.id,
        "clusterIds: ",
        clusterInfo.ids
      )();
      const colorScaleList = createColorScale(flowPlotItem, clusterInfo, topLayer);
      drawPoints(
        flowPlotItem.xUnbounded, flowPlotItem.yUnbounded, flowPlotItem.density, flowPlotItem.layers,
        xScale, yScale, canvasRef.current!, scatterplotType, colorScaleList
      );

      setCount(count + 1);
    }
  });

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && count > 0 && !dataIsLoading) {

      logger.info(
        "ClusterFlowPlot.useEffect => flowPlotItem",
        "FlowPlot",
        flowPlotItem.id,
        "clusterIds: ",
        clusterInfo.ids
      )();
      const colorScaleList = createColorScale(flowPlotItem, clusterInfo, topLayer);
      drawPoints(
        flowPlotItem.x, flowPlotItem.y, flowPlotItem.density, flowPlotItem.layers, xScale, yScale,
        canvasRef.current!, scatterplotType, colorScaleList
      );
    }
  }, [flowPlotItem.channelX, flowPlotItem.channelY, flowPlotItem.x, clusterInfo.idsShown,
    topLayer]
  );

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && quadrantInfo.quadrantId !== 0
      && count > 0 && !dataIsLoading) {
      logger.info(
        "ClusterFlowPlot.useEffect => quadrantId",
        "FlowPlot",
        flowPlotItem.id,
        "quadrant clicked on: ",
        quadrantInfo.quadrantId
      )();
      const shouldAddQuadrant = checkIfShouldAddQuadrant(newFlowPlotItem, quadrantInfo);
      if (shouldAddQuadrant) {
        addQuadrantSubPlot(newFlowPlotItem.id, quadrantInfo, setFlowPlotList,
          ref as React.MutableRefObject<Array<FlowPlotItem>>
        );
      }
    }
  }, [quadrantInfo.quadrantId]);

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && count > 0 && !dataIsLoading) {
      logger.info(
        "ClusterFlowPlot.useEffect => verticalLine, horizontalLine",
        "FlowPlot",
        flowPlotItem.id,
        "quadrant origin updated: ",
        quadrantInfo.verticalLine,
        quadrantInfo.horizontalLine
      )();
      updateQuadrantInfo(flowPlotItem.id, quadrantInfo, setFlowPlotList,
        ref as React.MutableRefObject<Array<FlowPlotItem>>);
    }
  }, [quadrantInfo.verticalLine, quadrantInfo.horizontalLine]);

  return (
    <>
      <Row className="plot-title">
        <Col className="col-9 offset-3">
          <PlotTitle title={flowPlotItem.populationName} />
        </Col>
      </Row>
      <Row className="density-scatterplot">
        <Col className="col-1 no-horizontal-padding">
          <ChannelDropdown col={1} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref} dropdownType="plot"
          />
        </Col>
        <Col className="col-1 no-horizontal-padding">
          {flowPlotItem.y.length > 1 &&
            <RangeSlider
              flowPlotItem={flowPlotItem} setFlowPlotList={setFlowPlotList} axis="y" ref={ref}
            />
          }
        </Col>
        <Col className="col-10">
          <Axes
            width={flowPlotItem.width} height={flowPlotItem.height}
            xScale={xScale} yScale={yScale} margin={flowPlotItem.margin}
          />
          <canvas
            width={flowPlotItem.boundsWidth} height={flowPlotItem.boundsHeight} ref={canvasRef}
            style={{ marginLeft: flowPlotItem.margin.left, marginTop: flowPlotItem.margin.top }}
          />
          <Legend flowPlotItem={newFlowPlotItem} clusterInfo={clusterInfo}
            flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList}
            scatterplotType={scatterplotType} setTopLayer={setTopLayer} ref={ref}
          />
          <ChartOptions flowPlotItem={flowPlotItem}
            setFlowPlotList={setFlowPlotList} quadrantInfo={quadrantInfo}
            setQuadrantInfo={setQuadrantInfo} ref={ref} />
        </Col>
      </Row>
      <Row className="no-vertical-padding">
        <Col className="col-10 offset-2">
          {flowPlotItem.x.length > 1 &&
            <RangeSlider
              flowPlotItem={flowPlotItem} setFlowPlotList={setFlowPlotList} axis="x" ref={ref}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col className="col-6 offset-4">
          <ChannelDropdown col={0} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref} dropdownType="plot"
          />
        </Col>
      </Row>
    </>
  );
})
);


/**
 * Get the density color scale for each cluster ID.
 *
 * Each cluster ID is assigned a color, and then the opacity of that color is based on the density.
 *
 * @param flowPlotItem - the FlowPlotItem we are creating a color scale for.
 * @param clusterInfo - object containing information about the cluster IDs, labels,
 *  and which ids are shown.
 * @returns an array containing a dictionary with a different color scale for each cluster ID.
 */
function createColorScale(flowPlotItem: FlowPlotItem, clusterInfo: ClusterInfo, topLayer: number):
  Array<ColorScaleInfo> {

  let colorScaleList = [];
  const clusterColors = [...CLUSTER_COLORS];


  const index = clusterInfo.ids.indexOf(topLayer);
  const clusterIds = [...clusterInfo.ids];
  clusterIds.splice(index, 1);
  clusterIds.push(topLayer);

  for (const clusterId of clusterIds) {
    if (!clusterInfo.idsShown.includes(clusterId)) {
      clusterColors.splice(clusterId, 1, "grey");
    }
  }

  colorScaleList = clusterIds.map((clusterId) => {
    const color = clusterColors[clusterId];
    const colorScale = d3.scaleLinear<string, string>()
      .domain([
        flowPlotItem.minDensity,
        flowPlotItem.maxDensity
      ]).range([color, "#ffffff"]).clamp(true);
    return ({
      clusterId: clusterId,
      colorScale: colorScale
    });
  });
  return colorScaleList;
}


interface LegendProps {
  flowPlotItem: FlowPlotItem;
  clusterInfo: ClusterInfo;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  scatterplotType: "umap" | "cluster" | "density";
  setTopLayer: React.Dispatch<React.SetStateAction<number>>;
}

const Legend = forwardRef(function Legend(
  { flowPlotItem, clusterInfo, flowPlotList, setFlowPlotList, scatterplotType,
    setTopLayer }: LegendProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const legend = clusterInfo.labels.map((value, index) => {
    return (
      <>
        <circle
          r={5}
          cx={flowPlotItem.boundsWidth + 25}
          cy={20 * index}
          opacity={1}
          stroke={CLUSTER_COLORS[index]}
          fill={CLUSTER_COLORS[index]}
          fillOpacity={1}
          strokeWidth={0.5}
          id={index.toString()}
          key={index.toString()}
          className="clickable"
          onClick={(event) => updateTopLayer(
            event, flowPlotItem, scatterplotType, flowPlotList, setFlowPlotList, setTopLayer,
            ref as React.MutableRefObject<Array<FlowPlotItem>>
          )}
        />
        <text x={flowPlotItem.boundsWidth + 30} y={20 * index} dx="1em" dy="0.4em"
          key={(index + clusterInfo.labels.length).toString()}>
          {value}
        </text>
      </>
    );
  });
  return (
    <>
      <svg width={flowPlotItem.width} height={flowPlotItem.height} key="legend">
        <g
          key="legend-g"
          width={flowPlotItem.boundsWidth}
          height={flowPlotItem.boundsHeight}
          transform={`translate(${[flowPlotItem.margin.left, flowPlotItem.margin.top].join(',')})`}
        >
          {legend}
        </g>
      </svg>
    </>
  );
});


function updateTopLayer(
  event: React.MouseEvent<SVGCircleElement>,
  flowPlotItem: FlowPlotItem,
  scatterplotType: "umap" | "cluster" | "density",
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  setTopLayer: React.Dispatch<React.SetStateAction<number>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): void {

  logger.info(
    "updateTopLayer",
    "layers",
    flowPlotItem.id,
    "flowPlotList: ",
    flowPlotList
  )();
  const target = event.target as HTMLElement;
  const topLayer = parseInt(target.getAttribute("id")!);
  const flowPlotItemToUpdate = new FlowPlotItem({
    ...flowPlotItem.toObject(),
    topLayer: topLayer
  })

  logger.info(
    "updateTopLayer",
    "layers",
    flowPlotItem.id,
    "flowPlotItemToUpdate: ",
    flowPlotItemToUpdate
  )();

  logger.info(
    "updateTopLayer",
    "layers",
    flowPlotItem.id,
    "flowPlotItemToUpdate.flowPlotSubList: ",
    flowPlotItemToUpdate.flowPlotSubList
  )();

  logger.info(
    "updateTopLayer",
    "layers",
    flowPlotItem.id,
    "flowPlotList: ",
    flowPlotListRef.current
  )();

  setTopLayer(topLayer);

  if (scatterplotType === "cluster") {
    const newFlowPlotList = replaceItemInFlowPlotListTree(
      flowPlotItemToUpdate, flowPlotListRef.current
    );
    updateFlowPlotList(newFlowPlotList, setFlowPlotList, flowPlotListRef);
    logger.info(
      "updateTopLayer",
      "layers",
      flowPlotItem.id,
      "newFlowPlotList: ",
      newFlowPlotList
    )();
  }
}
