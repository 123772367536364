import { Progress } from "../components/Buttons";
import { FlowFileInfo } from "../components/Files";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../utils/Logger";
import { ApiError,apiPost, AuthenticationError } from "./Api";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


export class UploadProgress extends Progress {
  firestore: number | null;
  cluster: number | null;

  constructor(value = 0, start = 0, end = 100,
    firestore: number | null = null, cluster: number | null = null) {

    super(value, start, end);
    this.firestore = firestore;
    this.cluster = cluster;
  }
}



/**
 * Upload file to Firebase Storage.
 *
 * @param file - the React state object containing the file.
 * @param firestoreDocument - the path to the Firestore Database Document that you would like to
 *  update with a link to your file in Firebase Storage.
 * @param firestoreField - the field name in the Firestore Database Document where you will
 *  add the link to your file in Firebase Storage.
 * @param maxFileSize - the maximum size of the file that can be uploaded, in MB. If the file
 *  is larger, it will be uploaded in chunks.
 */
export async function uploadFile(
  file: File,
  progress: Progress,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  firestoreDocument: string,
  firestoreField: string,
  maxFileSize=30
): Promise<void> {

  const fileSize = file.size;
  const maxFileSizeBytes = maxFileSize * 1024 * 1024;
  const totalChunks = Math.ceil(fileSize / (maxFileSizeBytes));
  let chunkSize = Math.ceil(fileSize / totalChunks);
  if (fileSize <= maxFileSizeBytes) {
    chunkSize = fileSize;
  }

  const progressIncrement = 1 / totalChunks;
  const progressInterval = progress.end - progress.start;

  try {
    const chunks = [...Array(totalChunks).keys()];
    await Promise.all(chunks.map(async (chunkNumber: number) => {
      await uploadFileChunk(
        file, chunkNumber, chunkSize, totalChunks, firestoreDocument, firestoreField
      );
      progress = {
        ...progress,
        value: Math.floor(progress.start + progressInterval * 0.5 * progressIncrement),
        start: Math.floor(progress.start + progressInterval * 0.5 * progressIncrement)
      };
      setProgress(progress);
    }));

    if (totalChunks > 1) {
      const formData = new FormData();
      formData.append("fileName", file.name);
      formData.append("totalChunks", JSON.stringify(totalChunks));
      formData.append("firestoreDocument", firestoreDocument);
      formData.append("firestoreField", firestoreField);


      logger.info(
        "uploadFile",
        "files",
        "Merging files: ",
        file.name
      )();

      const urlSuffix = "/merge_files";
      const responseData = await apiPost(urlSuffix, formData);
      logger.info(
        "uploadFile",
        "files",
        "responseData",
        responseData
      )();
    }

  } catch (error) {
    logger.error(
      "uploadFile",
      "Error while loading data from file: ",
      file.name,
      error
    )();
    throw new ApiError(
      `Error while uploading data from file: ${file.name}`
    );
  } finally {
    setProgress(new Progress(progress.end, progress.end, progress.end));
  }
}



/**
 * Upload a chunk of an *.fcs file to Firebase Storage.
 *
 * Firebase Cloud Functions have a maximum limit of 32Mb for data transfer in an HTTP request.
 * As a result, the flow data from a large file needs to be split into smaller chunks.
 *
 * @param fileName - the file name to load data from (must be *.fcs).
 * @param numEvents - the number of events to load in a single chunk.
 * @param chunkNumber - the index of the chunk, out of the total number of chunks.
 * @param totalChunks - the total number of chunks for the file.
 * @param firestoreDocument - the path to the Firestore Database Document that you would like to
 *  update with a link to your file in Firebase Storage.
 * @param firestoreField - the field name in the Firestore Database Document where you will
 *  add the link to your file in Firebase Storage.
 */
async function uploadFileChunk(file: File,
  chunkNumber: number, chunkSize: number, totalChunks: number,
  firestoreDocument: string, firestoreField: string
): Promise<void> {

  const start = chunkNumber * chunkSize;
  const end = start + chunkSize;

  try {
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("fileName", file.name);
    formData.append("chunkNumber", JSON.stringify(chunkNumber));
    formData.append("totalChunks", JSON.stringify(totalChunks));
    formData.append("firestoreDocument", firestoreDocument);
    formData.append("firestoreField", firestoreField);

    logger.info(
      "uploadFileChunk",
      "files",
      "Uploading file chunk: ",
      `${file.name} - chunk ${chunkNumber}`,
      `\nstart: ${start}, end: ${end}`
    )();

    const urlSuffix = "/upload_file";
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "uploadFileChunk",
      "files",
      "responseData",
      responseData
    )();
    return;
  } catch (error) {
    logger.error(
      "uploadFileChunk",
      error
    )();
    return;
  }
}


export async function getUploadProgress(
  fileName: string,
  progress: Progress,
  waitForCluster=false
): Promise<UploadProgress> {
  const urlSuffix = "/get_upload_progress";
  const formData = new FormData();
  formData.append("fileName", fileName);
  const uploadProgress = new UploadProgress(
    progress.end,
    progress.start,
    progress.end
  );

  try {
    const responseData = await apiPost(urlSuffix, formData);
    const progressInterval = progress.end - progress.start;
    uploadProgress.firestore = JSON.parse(responseData.progressFirestore);
    uploadProgress.cluster = JSON.parse(responseData.progressCluster);

    if (waitForCluster && uploadProgress.cluster != null && uploadProgress.cluster < 1.0) {
      if (uploadProgress.cluster === -1.0) {
        uploadProgress.value = -1.0;
      } else {
        uploadProgress.value = progress.value + progressInterval * uploadProgress.cluster;
      }
    } else if (uploadProgress.firestore != null) {
      if (uploadProgress.firestore === -1.0) {
        uploadProgress.value = -1.0;
      } else {
        uploadProgress.value = progress.value + progressInterval * uploadProgress.firestore;
      }
    } else {
      uploadProgress.value = progress.value;
    }
    logger.info(
      "getUploadProgress",
      "files",
      "uploadProgress",
      uploadProgress
    )();
    return uploadProgress;
  } catch (error) {
    if (error instanceof AuthenticationError) {
      logger.info(
        "getUploadProgress",
        "files",
        "user is not logged in"
      )();
    } else {
      logger.error(
        "getUploadProgress",
        error
      )();
    }
    return uploadProgress;
  }
}

export async function getFileList(
  setFlowFileStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
): Promise<void> {
  const urlSuffix = "/list_fcs_files";
  const formData = new FormData();
  const fileDocFields = [
    "progress_firestore",
    "progress_cluster",
    "is_clustered",
    "total_events",
    "total_chunks",
    "num_events_per_chunk",
    "num_events_per_chunk_http"
  ];
  formData.append("fileDocFields", JSON.stringify(fileDocFields));

  try {
    const responseData = await apiPost(urlSuffix, formData);
    const fileInfoList: Array<Record<string, any>> = JSON.parse(responseData.fileInfoList);
    logger.info(
      "getFileList",
      "files",
      "fileInfoList",
      fileInfoList
    )();

    // TEMPORARY FOR ANDY AND XUEHAI
    const FILES_TO_USE = [
      "xBF24-2854_BM_BCC_A_tubeT_clustered_11b669_knn100_lcf0.4_scs50_c18.fcs",
      "xBF24-2858_OT_BCC_A_tubeB1_clustered_4310c0_knn100_lcf0.4_scs100_c21.fcs",
      "xBF24-2863_PB_BCC_A_tubeB1_clustered_94ab08_knn100_lcf0.4_scs100_c21.fcs",
      "xBF24-2868_LN_BCC_A_tubeB1_clustered_1c9cf0_knn100_lcf0.6_scs100_c19.fcs",
      "xBF24-2870_BM_BCC_A_tubeT_clustered_2cbd16_knn100_lcf0.4_scs50_c18.fcs",
      "xBF24-2876_OT_BCC_A_tubeB1_clustered_58541f_knn100_lcf0.4_scs50_c17.fcs",
      "xBF24-2876_OT_BCC_A_tubeT_clustered_2a81c6_knn100_lcf0.4_scs50_c14.fcs",
      "xBF24-2892_PB_BCC_A_tubeB1_clustered_bb96fb_knn100_lcf0.6_scs100_c16.fcs",
      "xBF24-2881_OT_BCC_A_tubeT_clustered_767b27_knn100_lcf0.4_scs50_c14.fcs",
      "xBF24-2886_BM_BCC_A_tubeT_clustered_3326a4_knn100_lcf0.4_scs50_c18.fcs"
    ];
    const newFlowFileStorageList: Array<FlowFileInfo> = [];
    fileInfoList.forEach((fileInfo) => {
      if (FILES_TO_USE.includes(fileInfo.name)) {
        const flowFileInfo = new FlowFileInfo({
          name: fileInfo.name,
          location: "storage",
          type: "",
          progress: {
            firestore: fileInfo.progress_firestore,
            cluster: fileInfo.progress_cluster
          },
          totalEvents: fileInfo.total_events != null
            ? parseInt(fileInfo.total_events)
            : undefined,
          totalChunks: fileInfo.total_chunks != null
            ? parseInt(fileInfo.total_chunks)
            : undefined,
          numEventsPerChunk: fileInfo.num_events_per_chunk != null
            ? parseInt(fileInfo.num_events_per_chunk)
            : undefined,
          numEventsPerChunkHTTP: fileInfo.num_events_per_chunk_http != null
            ? parseInt(fileInfo.num_events_per_chunk_http)
            : undefined,
          isClustered: fileInfo.is_clustered != null
            ? fileInfo.is_clustered
            : undefined
        });
        newFlowFileStorageList.push(flowFileInfo);
      }
    });

    // END TEMPORARY FOR ANDY AND XUEHAI
    // UNCOMMENT CHUNK BELOW TO USE ALL FILES

    // const newFlowFileStorageList = fileInfoList.map((fileInfo) => {
    //   return new FlowFileInfo({
    //     name: fileInfo.name,
    //     location: "storage",
    //     type: "",
    //     progress: {
    //       firestore: fileInfo.progress_firestore,
    //       cluster: fileInfo.progress_cluster
    //     },
    //     totalEvents: fileInfo.total_events != null
    //       ? parseInt(fileInfo.total_events)
    //       : undefined,
    //     totalChunks: fileInfo.total_chunks != null
    //       ? parseInt(fileInfo.total_chunks)
    //       : undefined,
    //     numEventsPerChunk: fileInfo.num_events_per_chunk != null
    //       ? parseInt(fileInfo.num_events_per_chunk)
    //       : undefined,
    //     numEventsPerChunkHTTP: fileInfo.num_events_per_chunk_http != null
    //       ? parseInt(fileInfo.num_events_per_chunk_http)
    //       : undefined,
    //     isClustered: fileInfo.is_clustered != null
    //       ? fileInfo.is_clustered
    //       : undefined
    //   });
    // });

    logger.info(
      "getFileList",
      "files",
      newFlowFileStorageList
    )();
    setFlowFileStorageList(newFlowFileStorageList);
  } catch (error) {
    if (error instanceof AuthenticationError) {
      logger.info(
        "getFileList",
        "files",
        "user is not logged in"
      )();
    } else {
      logger.error(
        "getFileList",
        error
      )();
    }
  }
}


export async function deleteFile(
  fileName: string,
  setFlowFileStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
): Promise<void> {
  const urlSuffix = "/delete_fcs_file";
  const formData = new FormData();
  formData.append("fileName", fileName);

  try {
    await apiPost(urlSuffix, formData);
    await getFileList(setFlowFileStorageList);
  } catch (error) {
    if (error instanceof AuthenticationError) {
      logger.info(
        "deleteFile",
        "files",
        "user is not logged in"
      )();
    } else {
      logger.error(
        "deleteFile",
        error
      )();
    }
  }
}


export async function checkIfClustered(
  fileName: string
): Promise<boolean | undefined> {
  const urlSuffix = "/check_if_clustered";
  const formData = new FormData();
  formData.append("fileName", fileName);

  try {
    const responseData = await apiPost(urlSuffix, formData);
    const isClustered = JSON.parse(responseData.isClustered);
    return isClustered;
  } catch (error) {
    if (error instanceof AuthenticationError) {
      logger.info(
        "checkIfClustered",
        "files",
        "user is not logged in"
      )();
    } else {
      logger.error(
        "checkIfClustered",
        error
      )();
    }
  }
}


export async function getChunkMetadata(
  fileInfo: FlowFileInfo
): Promise<FlowFileInfo> {
  try {
    const formData = new FormData();

    formData.append("fileName", fileInfo.name);
    const urlSuffix = "/get_chunk_metadata";
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "loadData",
      "api",
      "responseData",
      responseData
    )();

    const totalEvents = parseInt(responseData.totalEvents);
    const numEventsPerChunk = parseInt(responseData.numEventsPerChunk);
    const numEventsPerChunkHTTP = parseInt(responseData.numEventsPerChunkHTTP);
    const newFileInfo = new FlowFileInfo({
      ...fileInfo,
      totalEvents: totalEvents,
      numEventsPerChunk: numEventsPerChunk,
      numEventsPerChunkHTTP: numEventsPerChunkHTTP
    });
    return newFileInfo;
  } catch (error) {
    logger.error(
      "loadData",
      "Error while loading data from file: ",
      fileInfo.name,
      error
    )();
    return fileInfo;
  }
}
