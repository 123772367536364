import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { ArrowsMove } from 'react-bootstrap-icons';

import { getPlotData } from "../../api/manual-gate/LoadData";
import { 
  FlowPlotItem, replaceItemInFlowPlotListTree 
} from '../../pages/manual-gate/plots/FlowPlotItem';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { QuadrantInfo, QuadrantLayer, updateQuadrantInfo } from './Quadrants';

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface ChartOptionsProps {
  flowPlotItem: FlowPlotItem;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  quadrantInfo: QuadrantInfo;
  setQuadrantInfo: React.Dispatch<React.SetStateAction<QuadrantInfo>>;
}

export const ChartOptions = forwardRef(function ChartOptions(
  { flowPlotItem, setFlowPlotList, quadrantInfo, setQuadrantInfo }: ChartOptionsProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const chartOptionsHeight = 50;

  function updateShouldDraw(quadrantInfo: QuadrantInfo): void {
    let newDrawState: "notDrawn" | "activeDrawing" | "staticDrawn" = "notDrawn";
    switch (quadrantInfo.drawState) {
      case "notDrawn":
        newDrawState = "activeDrawing";
        break;
      case "activeDrawing":
        newDrawState = "staticDrawn";
        break;
      case "staticDrawn":
        newDrawState = "notDrawn";
        break;
      default:
        newDrawState = "notDrawn";
    }
    const newQuadrantInfo = new QuadrantInfo({
      ...quadrantInfo.toObject(),
      drawState: newDrawState
    })

    logger.info(
      "updateShouldDraw",
      "ChartOptions",
      "updated drawState to ",
      newDrawState
    )();

    updateQuadrantInfo(flowPlotItem.id, newQuadrantInfo, setFlowPlotList,
      ref as React.MutableRefObject<Array<FlowPlotItem>>
    );

    logger.info(
      "updateShouldDraw",
      "ChartOptions",
      "newQuadrantInfo",
      newQuadrantInfo.horizontalLine
    )();
    setQuadrantInfo(newQuadrantInfo);
    return;
  }

  return (
    <>
      <svg width={flowPlotItem.width} height={flowPlotItem.height}>
        <g width={flowPlotItem.boundsWidth} height={chartOptionsHeight}>
          <rect
            width={20}
            height={20}
            x={0}
            y={0}
            opacity={1}
            stroke={"white"}
            fill={"white"}
            fillOpacity={0}
            strokeWidth={0.5}
            onClick={() => updateShouldDraw(quadrantInfo)}
            className="clickable"
          />
          <ArrowsMove size={20} className="icon" />
        </g>
        <QuadrantLayer quadrantInfo={quadrantInfo} setQuadrantInfo={setQuadrantInfo}
          boundsWidth={flowPlotItem.boundsWidth} boundsHeight={flowPlotItem.boundsHeight}
        />
      </svg>
    </>
  );
});


interface RangeSliderProps {
  flowPlotItem: FlowPlotItem;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  axis: "x" | "y";
}

export const RangeSlider = forwardRef(function RangeSlider(
  { flowPlotItem, setFlowPlotList, axis }: RangeSliderProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>
): React.ReactElement {

  const [count, setCount] = useState(0);
  const [lowerBound, setLowerBound] = useState<number>(
    axis === "x" ? Math.min(...flowPlotItem.x) : Math.min(...flowPlotItem.y)
  );
  const [upperBound, setUpperBound] = useState<number>(
    axis === "x" ? Math.max(...flowPlotItem.x) : Math.max(...flowPlotItem.y)
  );
  const [lowerRange, upperRange, step] = useMemo(
    () => {
      const [lowerRange, upperRange] = getRange(flowPlotItem, axis);
      const step = (upperRange - lowerRange) / 10;
      return [lowerRange, upperRange, step];
    },
    [flowPlotItem.channelX, flowPlotItem.channelY]
  );

  function getRange(flowPlotItem: FlowPlotItem, axis: "x" | "y"): Array<number> {
    let lowerRange: number;
    let upperRange: number;
    
    if (axis === "x") {
      lowerRange = Math.min(...flowPlotItem.xUnbounded);
      upperRange = Math.max(...flowPlotItem.xUnbounded);
    } else {
      lowerRange = Math.min(...flowPlotItem.yUnbounded);
      upperRange = Math.max(...flowPlotItem.yUnbounded);
    }
    return [lowerRange, upperRange];
  }

  useEffect(() => {
    if (count === 0) {
      logger.info(
        "useEffect => mount",
        "RangeSlider",
        "count: ",
        count
      )();
      setCount(count + 1);
    }
  });

  useEffect(() => {
    if (count > 0) {
      const [newLowerRange, newUpperRange] = getRange(flowPlotItem, axis);
      logger.info(
        "useEffect",
        "RangeSlider",
        "setting bounds to ",
        [newLowerRange, newUpperRange]
      )(
      )
      setBounds([newLowerRange, newUpperRange]);
    }
  }, [flowPlotItem.channelX, flowPlotItem.channelY]);

  // useEffect(() => {
  //   const callUpdateBounds = async () => {
  //     await updateBounds([undefined, undefined]);
  //   }
  //   callUpdateBounds().catch((error) => {
  //     logger.error(
  //       "useEffect",
  //       "RangeSlider",
  //       "error updating bounds",
  //       error
  //       )();
  //   }
  //   );
  // }, []);

  function formatText(value: number): string {
    if (value <= 100 && value >= -100) {
      return value.toFixed(2);
    } else {
      return value.toExponential(2);
    }
  }

  function setBounds(bounds: Array<number>): void {
    setLowerBound(bounds[0]);
    setUpperBound(bounds[1]);
  }

  async function updateBounds(bounds: Array<number>): Promise<void> {

    let xMin: number;
    let xMax: number;
    let yMin: number;
    let yMax: number;

    if (axis === "x") {
      xMin = bounds[0];
      xMax = bounds[1];
      yMin = Math.min(...flowPlotItem.y);
      yMax = Math.max(...flowPlotItem.y);
    } else {
      xMin = Math.min(...flowPlotItem.x);
      xMax = Math.max(...flowPlotItem.x);
      yMin = bounds[0];
      yMax = bounds[1];
    }

    const [newX, newY, density, maxDensity, minDensity] = await getPlotData(
      flowPlotItem.xUnbounded, flowPlotItem.yUnbounded, flowPlotItem.channelX,
      flowPlotItem.channelY, flowPlotItem.indices, xMin, xMax, yMin, yMax
    );
    logger.info(
      "updateBounds",
      "RangeSlider",
      "flowPlotItem",
      flowPlotItem.toObject()
    )();
    const newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      x: newX,
      y: newY,
      density: density,
      maxDensity: maxDensity,
      minDensity: minDensity
    });

    logger.info(
      "updateBounds",
      "RangeSlider",
      "updated bounds to ",
      bounds
    )();
    logger.info(
      "updateBounds",
      "RangeSlider",
      "newFlowPlotItem",
      newFlowPlotItem
    )(
    )

    const newFlowPlotList = replaceItemInFlowPlotListTree(
      newFlowPlotItem, (ref as React.MutableRefObject<Array<FlowPlotItem>>).current
    );
    (ref as React.MutableRefObject<Array<FlowPlotItem>>).current = newFlowPlotList;
    setFlowPlotList(newFlowPlotList);
    if (axis === "x") {
      setLowerBound(Math.min(...newX));
      setUpperBound(Math.max(...newX));
    } else {
      setLowerBound(Math.min(...newY));
      setUpperBound(Math.max(...newY));
    }
  }

  return (
    <Box sx={{ width: flowPlotItem.boundsWidth }}>
      {axis === "x" &&
        <Slider
        getAriaLabel={() => 'x-axis'}
        getAriaValueText={formatText}
        valueLabelFormat={formatText}
        value={[lowerBound, upperBound]}
        onChangeCommitted={(_event, newBounds) => updateBounds(newBounds as Array<number>)}
        onChange={(_event, newBounds) => {setBounds(newBounds as Array<number>)}}
        valueLabelDisplay="auto"
        min={lowerRange}
        max={upperRange}
        step={step}
        orientation={"horizontal"}
      />
      }
      {axis === "y" &&
        <Slider
        className="slider-vertical"
        getAriaLabel={() => 'y-axis'}
        getAriaValueText={formatText}
        valueLabelFormat={formatText}
        value={[lowerBound, upperBound]}
        onChangeCommitted={(_event, newBounds) => updateBounds(newBounds as Array<number>)}
        onChange={(_event, newBounds) => {setBounds(newBounds as Array<number>)}}
        valueLabelDisplay="auto"
        min={lowerRange}
        max={upperRange}
        step={step}
        orientation={"vertical"}
      />
      }
    </Box>
  );
});