import * as d3 from 'd3';
import React, { forwardRef,memo, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import type { FlowData } from '../../../components/FlowData';
import { ChartOptions, RangeSlider } from '../../../components/plots/ChartOptions';
import { Axes, PlotTitle } from '../../../components/plots/PlotComponents';
import {
  addQuadrantSubPlot, QuadrantInfo, updateQuadrantInfo
} from '../../../components/plots/Quadrants';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import { ChannelDropdown } from '../channels/Channels';
import { FlowPlotItem,getFlowPlotItemById } from './FlowPlotItem';
import { checkIfShouldAddQuadrant,createXScale, createYScale, drawPoints } from "./FlowPlots";


const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);


interface DensityFlowPlotProps {
  flowPlotItem: FlowPlotItem;
  /** The master list of all the data for the different plots. */
  flowPlotList: Array<FlowPlotItem>;
  /** The setState method for flowPlotList. */
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
  /** The margins in pixels */
  dataIsLoading: boolean;
}

export const DensityFlowPlot = memo(forwardRef(function(
  { flowPlotItem, flowPlotList, setFlowPlotList, flowData, dataIsLoading }: DensityFlowPlotProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const xScale = createXScale(flowPlotItem.x, flowPlotItem.boundsWidth);
  const yScale = createYScale(flowPlotItem.y, flowPlotItem.boundsHeight);
  const scatterplotType = "density";
  const [count, setCount] = useState(0);
  let newFlowPlotItem = new FlowPlotItem({});

  if (ref != null && typeof ref !== 'function') {
    newFlowPlotItem = getFlowPlotItemById(flowPlotItem.id, ref.current!)[1];
  }

  const [quadrantInfo, setQuadrantInfo] = useState<QuadrantInfo>(
    () => {
      return newFlowPlotItem.quadrantInfo === undefined
        ? new QuadrantInfo({
          boundsHeight: flowPlotItem.boundsHeight,
          boundsWidth: flowPlotItem.boundsWidth,
          margin: flowPlotItem.margin
        })
        : newFlowPlotItem.quadrantInfo
    }
  );

  logger.info(
    "DensityFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "canvasRef.current: ",
    canvasRef.current
  )();

  logger.info(
    "DensityFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "newFlowPlotItem: ",
    newFlowPlotItem
  )();

  logger.info(
    "DensityFlowPlot",
    "FlowPlot",
    flowPlotItem.id,
    "flowPlotItem: ",
    flowPlotItem
  )();

  useEffect(() => {

    if (canvasRef.current && flowPlotItem.x.length > 1 && count === 0 && !dataIsLoading) {
      logger.info(
        "DensityFlowPlot.useEffect => mount",
        "FlowPlot",
        flowPlotItem.id,
        "count: ",
        count
      )();
      const colorScaleList = createColorScale(flowPlotItem);
      const layers = {
        "0": [...Array(flowPlotItem.x.length).keys()]
      }
      drawPoints(
        flowPlotItem.xUnbounded, flowPlotItem.yUnbounded, flowPlotItem.density, layers,
        xScale, yScale, canvasRef.current!, scatterplotType, colorScaleList
      );
      setCount(count + 1);
    }
  });

  useEffect(() => {

    if (canvasRef.current && flowPlotItem.x.length > 1 && count > 0 && !dataIsLoading) {
      logger.info(
        "DensityFlowPlot.useEffect => flowPlotItem, xScale, yScale",
        "FlowPlot",
        flowPlotItem.id,
        "count: ",
        count
      )();
      const colorScaleList = createColorScale(flowPlotItem);
      const layers = {
        "0": [...Array(flowPlotItem.x.length).keys()]
      }
      drawPoints(
        flowPlotItem.x, flowPlotItem.y, flowPlotItem.density, layers, xScale, yScale,
        canvasRef.current!, scatterplotType, colorScaleList
      );
    }
  }, [flowPlotItem]);

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && quadrantInfo.quadrantId !== 0
      && count > 0 && !dataIsLoading) {
      logger.info(
        "DensityFlowPlot.useEffect => quadrantId",
        "FlowPlot",
        flowPlotItem.id,
        "quadrant clicked on: ",
        quadrantInfo.quadrantId
      )();
      const shouldAddQuadrant = checkIfShouldAddQuadrant(newFlowPlotItem, quadrantInfo);
      if (shouldAddQuadrant) {
        addQuadrantSubPlot(
          flowPlotItem.id, quadrantInfo, setFlowPlotList,
          ref as React.MutableRefObject<Array<FlowPlotItem>>
        );
      }
    }
  }, [quadrantInfo.quadrantId]);

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && count > 0 && !dataIsLoading) {
      logger.info(
        "DensityFlowPlot.useEffect => verticalLine, horizontalLine",
        "FlowPlot",
        flowPlotItem.id,
        "origin updated: ",
        quadrantInfo.verticalLine,
        quadrantInfo.horizontalLine
      )();
      updateQuadrantInfo(flowPlotItem.id, quadrantInfo, setFlowPlotList,
        ref as React.MutableRefObject<Array<FlowPlotItem>>);
    }
  }, [quadrantInfo.verticalLine, quadrantInfo.horizontalLine]);

  return (
    <>
      <Row className="plot-title">
        <Col className="col-9 offset-3">
          <PlotTitle title={flowPlotItem.populationName} />
        </Col>
      </Row>
      <Row className="density-scatterplot">
        <Col className="col-1 no-horizontal-padding">
          <ChannelDropdown col={1} flowPlotItem={newFlowPlotItem} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref} dropdownType="plot"
          />
        </Col>
        <Col className="col-1 no-horizontal-padding">
          {flowPlotItem.y.length > 1 &&
            <RangeSlider
              flowPlotItem={flowPlotItem} setFlowPlotList={setFlowPlotList} axis="y" ref={ref}
            />
          }
        </Col>
        <Col className="col-10">
          <Axes
            width={flowPlotItem.width} height={flowPlotItem.height}
            xScale={xScale} yScale={yScale} margin={flowPlotItem.margin}
          />
          <canvas
            width={flowPlotItem.boundsWidth} height={flowPlotItem.boundsHeight} ref={canvasRef}
            style={{ marginLeft: flowPlotItem.margin.left, marginTop: flowPlotItem.margin.top }} />
          <ChartOptions flowPlotItem={flowPlotItem}
            setFlowPlotList={setFlowPlotList}
            quadrantInfo={quadrantInfo} setQuadrantInfo={setQuadrantInfo}
            ref={ref} />
        </Col>
      </Row>
      <Row className="no-vertical-padding">
        <Col className="col-10 offset-2">
          {flowPlotItem.x.length > 1 &&
            <RangeSlider
              flowPlotItem={flowPlotItem} setFlowPlotList={setFlowPlotList} axis="x" ref={ref}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col className="col-6 offset-4">
          <ChannelDropdown col={0} flowPlotItem={newFlowPlotItem} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref} dropdownType="plot"
          />
        </Col>
      </Row>
    </>
  );
})
);


/**
 * Get the density color scale.
 *
 * This function is perhaps a bit misleading: it is assigning the same color scale regardless
 * of the cluster ID. This is because this function is only ever called from the case where
 * there is a single cluster ID.
 *
 * @param flowPlotItem - the FlowPlotItem we are creating a color scale for.
 * @returns - an array containing a different color scale for each
 *  cluster ID.
 */
function createColorScale(
  flowPlotItem: FlowPlotItem
): Array<{ clusterId: number, colorScale: d3.ScaleLinear<string, string> }> {
  const densityStep = (flowPlotItem.maxDensity - flowPlotItem.minDensity) / 4;
  const colorScale = d3.scaleLinear<string, string>().domain([
    flowPlotItem.minDensity,
    flowPlotItem.minDensity + densityStep,
    flowPlotItem.minDensity + 2 * densityStep,
    flowPlotItem.maxDensity])
    .range(['#060688', '#11faf2', '#fcff06', '#ca0101']).clamp(true);

  const colorScaleList = [
    {
      clusterId: 0,
      colorScale: colorScale
    }
  ]
  return colorScaleList;
}
