import { v4 as uuidv4 } from 'uuid';

import { getDataInQuadrant,getPlotData } from '../../../api/manual-gate/LoadData';
import type { FlowData } from "../../../components/FlowData";
import type { Margin } from '../../../components/plots/PlotComponents';
import { QuadrantInfo } from '../../../components/plots/Quadrants';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import { matchChannel } from "../channels/Channels";
import { createXScale, createYScale, HEIGHT,MARGIN, WIDTH } from "./FlowPlots";

const isEqual = require("react-fast-compare");

const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);


interface ConstructorParams {
  id?: string;
  populationName?: string;
  channelX?: string;
  channelY?: string;
  x?: Array<number>;
  y?: Array<number>;
  density?: Array<number>;
  minDensity?: number;
  maxDensity?: number;
  clusters?: Array<number>;
  layers?: Record<string, Array<number>>;
  topLayer?: number;
  flowPlotSubList?: Array<FlowPlotItem>;
  quadrantId?: number;
  numCells?: number;
  margin?: Margin;
  width?: number;
  height?: number;
  indices?: Array<number>;
  quadrantInfo?: QuadrantInfo;
  xUnbounded?: Array<number>;
  yUnbounded?: Array<number>;
}

class FlowPlotItem {

  id: string = uuidv4();
  populationName!: string;
  channelX!: string;
  channelY!: string;
  private _x: Array<number> = [0];
  private _y: Array<number> = [0];
  private _density!: Array<number>;
  maxDensity!: number;
  minDensity!: number;
  clusters!: Array<number>;
  layers!: Record<string, Array<number>>;
  topLayer!: number;
  flowPlotSubList!: Array<FlowPlotItem>;
  quadrantId!: number;
  /** the number of cells to use */
  private _numCells!: number;
  private _xScale!: d3.ScaleLinear<number, number>;
  private _yScale!: d3.ScaleLinear<number, number>;
  /** the margin from the edge of the plot to the beginning of the plotting section in pixels. */
  margin!: Margin;
  /** The width of the plot in pixels. */
  private _width!: number;
  /** The height of the plot in pixels. */
  private _height!: number;
  private _boundsHeight!: number;
  private _boundsWidth!: number;
  /** the indices of this data from the original *.fcs file */
  indices!: Array<number>;
  private _quadrantInfo?: QuadrantInfo;
  xUnbounded!: Array<number>;
  yUnbounded!: Array<number>;
  xDataIsSet!: boolean;
  yDataIsSet!: boolean;

  constructor({
    id = uuidv4(),
    populationName = "all",
    channelX = "",
    channelY = "",
    x = [0],
    y = [0],
    density = [0],
    minDensity = 0,
    maxDensity = 1,
    clusters = [0],
    layers = {},
    topLayer = 0,
    flowPlotSubList = [],
    quadrantId = 0,
    numCells = 1000,
    margin = MARGIN,
    width = WIDTH,
    height = HEIGHT,
    indices,
    quadrantInfo,
    xUnbounded,
    yUnbounded
  }: ConstructorParams
) {

    this.id = id;
    this.populationName = populationName;
    this.channelX = channelX;
    this.channelY = channelY;
    this.margin = margin;
    this.width = width;
    this.height = height;
    this.numCells = numCells;
    this.density = density;
    this.minDensity = minDensity;
    this.maxDensity = maxDensity;
    if (xUnbounded !== undefined) {
      this.xDataIsSet = true;
      this.xUnbounded = xUnbounded;
    } else {
      this.xDataIsSet = false;
    }
    if (yUnbounded !== undefined) {
      this.yDataIsSet = true;
      this.yUnbounded = yUnbounded;
    } else {
      this.yDataIsSet = false;
    }
    this.x = x;
    this.y = y;
    this.clusters = clusters;
    this.layers = layers;
    this.topLayer = topLayer;
    this.quadrantId = quadrantId;
    this.flowPlotSubList = flowPlotSubList;
    if (indices !== undefined) {
      this.indices = indices;
    }
    if (quadrantInfo !== undefined) {
      this.quadrantInfo = quadrantInfo;
    }
  }

  toObject(keys?: Array<string>): Record<string, any> {
    const object: Record<string, any> = {
      id: this.id,
      populationName: this.populationName,
      channelX: this.channelX,
      channelY: this.channelY,
      x: this.x,
      y: this.y,
      density: this.density,
      maxDensity: this.maxDensity,
      minDensity: this.minDensity,
      clusters: this.clusters,
      layers: this.layers,
      topLayer: this.topLayer,
      flowPlotSubList: this.flowPlotSubList,
      quadrantId: this.quadrantId,
      numCells: this.numCells,
      indices: this.indices,
      xScale: this.xScale,
      yScale: this.yScale,
      margin: this.margin,
      width: this.width,
      height: this.height,
      boundsHeight: this.boundsHeight,
      boundsWidth: this.boundsWidth,
      quadrantInfo: this.quadrantInfo === undefined
        ? undefined
        : this.quadrantInfo.toObject(),
      xUnbounded: this.xUnbounded,
      yUnbounded: this.yUnbounded,
      xDataIsSet: this.xDataIsSet,
      yDataIsSet: this.yDataIsSet
    };

    let newObject = {};
    if (keys !== undefined) {
      for (const key of Object.keys(object)) {
        if (keys.includes(key)) {
          newObject = {
            ...newObject,
            [key]: object[key]
          }
        }
      }
    } else {
      newObject = {
        ...object
      }
    }
    return newObject;
  }

  public set width(width: number) {
    this._width = width;
    this.boundsWidth = width - this.margin.right - this.margin.left;
  }

  public get width(): number {
    return this._width;
  }

  public set height(height: number) {
    this._height = height;
    this.boundsHeight = height - this.margin.top - this.margin.bottom;
  }

  public get height(): number {
    return this._height;
  }

  public set boundsWidth(boundsWidth: number) {
    this._boundsWidth = boundsWidth;
    this.xScale = createXScale(this.x, boundsWidth);
  }

  public get boundsWidth(): number {
    return this._boundsWidth;
  }

  public set boundsHeight(boundsHeight: number) {
    this._boundsHeight = boundsHeight;
    this.yScale = createYScale(this.y, boundsHeight);
  }

  public get boundsHeight(): number {
    return this._boundsHeight;
  }

  public set x(x: Array<number>) {
    this._x = x;
    this.xScale = createXScale(x, this.boundsWidth);
    if (x.length > 1 && !this.xDataIsSet) {
      this.xDataIsSet = true;
      this.xUnbounded = x;
    }
  }

  public get x(): Array<number> {
    return this._x;
  }

  public set y(y: Array<number>) {
    this._y = y;
    this.yScale = createYScale(y, this.boundsHeight);
    if (y.length > 1 && !this.yDataIsSet) {
      this.yDataIsSet = true;
      this.yUnbounded = y;
    }
  }

  public get y(): Array<number> {
    return this._y;
  }

  public set density(density: Array<number>) {
    this._density = density;
    // this.minDensity = findMin(newDensity);
    // this.maxDensity = findMax(newDensity);
  }

  public get density(): Array<number> {
    return this._density;
  }

  public get xScale(): d3.ScaleLinear<number, number> {
    return this._xScale;
  }

  public set xScale(xScale: d3.ScaleLinear<number, number>) {
    this._xScale = xScale;
  }

  public get yScale(): d3.ScaleLinear<number, number> {
    return this._yScale;
  }

  public set yScale(yScale: d3.ScaleLinear<number, number>) {
    this._yScale = yScale;
  }

  public set numCells(numCells: number) {
    this._numCells = Math.round(numCells);
    this.indices = [...Array(this._numCells).keys()];
  }

  public get numCells(): number {
    return this._numCells;
  }

  public set quadrantInfo(quadrantInfo: QuadrantInfo | Record<string, number>) {
    if (quadrantInfo instanceof QuadrantInfo) {
      this._quadrantInfo = quadrantInfo
    } else {
      this._quadrantInfo = new QuadrantInfo({
        ...quadrantInfo,
        boundsHeight: this.boundsHeight,
        boundsWidth: this.boundsWidth,
        margin: this.margin
      });
    }
  }

  public get quadrantInfo(): QuadrantInfo | undefined {
    return this._quadrantInfo;
  }

}


/**
 * Given a list of FlowPlotItems, convert each item in the list to an object.
 *
 * This is a recursive function, and will convert all items in the flowPlotSubList field as well.
 *
 * @param flowPlotList - the list of FlowPlotItems we wish to convert to objects.
 * @param keys - a list of fields from the FlowPlotItem class which we want
 *  to include in our object.
 * @returns a list of objects containing the data from the FlowPlotItems.
 */
export function convertFlowPlotListToObject(
  flowPlotList: Array<FlowPlotItem>, keys: Array<string>
): Array<Record<string, any>> {
  const flowPlotListObject = flowPlotList.map((flowPlotItem) => {
    return convertFlowPlotItemToObject(flowPlotItem, keys)
  });
  return flowPlotListObject;
}


/**
 * Given a FlowPlotItem, convert it to an object.
 *
 * This is a recursive function, and will convert all items in the flowPlotSubList field as well.
 *
 * @param flowPlotItem - the FlowPlotItem we wish to convert to an object.
 * @param keys - a list of fields from the FlowPlotItem class which we want
 *  to include in our object.
 * @returns an object containing the data from the FlowPlotItem.
 */
function convertFlowPlotItemToObject(
  flowPlotItem: FlowPlotItem, keys: Array<string>
): Record<string, any> {
  let flowPlotItemObject = flowPlotItem.toObject(keys);
  if (flowPlotItem.flowPlotSubList.length === 0) {
    return flowPlotItemObject;
  } else {
    const flowPlotSubListObject = convertFlowPlotListToObject(flowPlotItem.flowPlotSubList, keys);
    flowPlotItemObject = {
      ...flowPlotItemObject,
      flowPlotSubList: flowPlotSubListObject
    }
  }

  return flowPlotItemObject;
}


/**
 * Given a list of FlowPlotItems, convert each item in the list to an object.
 *
 * This is a recursive function, and will convert all items in the flowPlotSubList field as well.
 *
 * @param flowPlotList - the list of FlowPlotItems we wish to convert to objects.
 * @returns a list of objects containing the data from the FlowPlotItems.
 */
export function convertObjectsToFlowPlotList(
  flowPlotObjectList: Array<ConstructorParams>
): Array<FlowPlotItem> {
  const flowPlotList = flowPlotObjectList.map((flowPlotObject) => {
    return convertObjectToFlowPlotItem(flowPlotObject)
  });
  return flowPlotList;
}


/**
 * Given an object, convert it to a FlowPlotItem.
 *
 * This is a recursive function, and will convert all items in the flowPlotSubList field as well.
 *
 * @param flowPlotItem - the FlowPlotItem we wish to convert to an object.
 * @returns an object containing the data from the FlowPlotItem.
 */
function convertObjectToFlowPlotItem(
  flowPlotObject: ConstructorParams
): FlowPlotItem {
  const keys = Object.keys(flowPlotObject);
  let flowPlotItem = new FlowPlotItem({});

  if (!keys.includes("flowPlotSubList") ||
    (keys.includes("flowPlotSubList") && flowPlotObject!["flowPlotSubList"]!.length === 0)) {
    flowPlotItem = new FlowPlotItem({
      ...flowPlotObject
    });
  } else {
    const index = keys.indexOf("flowPlotSubList", 0);
    keys.splice(index, 1);
    const flowPlotSubList = convertObjectsToFlowPlotList(flowPlotObject!.flowPlotSubList!);

    flowPlotObject = {
      ...flowPlotObject,
      flowPlotSubList: flowPlotSubList
    };
    flowPlotItem = new FlowPlotItem(
      flowPlotObject
    );
  }

  if (keys.includes("quadrantInfo") && flowPlotObject!["quadrantInfo"] !== undefined) {
    flowPlotItem.quadrantInfo = new QuadrantInfo({
      ...flowPlotObject.quadrantInfo,
      boundsHeight: flowPlotItem.boundsHeight,
      boundsWidth: flowPlotItem.boundsWidth,
      margin: flowPlotItem.margin
    })
  }
  return flowPlotItem;
}


function setField<TObj, K extends keyof TObj>(
  obj: TObj, key: K, value: TObj[K]
): TObj {
  return { ...obj, [key]: value };
}



/**
 * Given two FlowPlotItems, determine if they are equal.
 *
 * For the time being, we will exclude quadrantInfo from this comparison; in the future,
 * I would like to be able to select which keys to exclude.
 *
 * @param flowPlotItemA - a FlowPlotItem to compare with flowPlotItemB.
 * @param flowPlotItemB - a FlowPlotItem to compare with flowPlotItemA.
 * @returns whether or not they are equal.
 */
function areFlowPlotItemsEqual(flowPlotItemA: FlowPlotItem, flowPlotItemB: FlowPlotItem,
  exclude: Array<keyof FlowPlotItem>, shallow = false):
  boolean {

  logger.info(
    "areFlowPlotItemsEqual",
    "props",
    flowPlotItemA.id,
    "flowPlotItemA:\n",
    flowPlotItemA,
    "\nflowPlotItemB:\n",
    flowPlotItemB
  )();

  if (!shallow && flowPlotItemA.flowPlotSubList.length !== flowPlotItemB.flowPlotSubList.length) {
    return false;
  } else {
    let newFlowPlotItemA = new FlowPlotItem({ ...flowPlotItemA.toObject() }).toObject();
    let newFlowPlotItemB = new FlowPlotItem({ ...flowPlotItemB.toObject() }).toObject();

    exclude = [...exclude, "xScale", "yScale"];

    for (const key of exclude) {
      newFlowPlotItemA = setField(newFlowPlotItemA, key, undefined);
      newFlowPlotItemB = setField(newFlowPlotItemB, key, undefined);
    }
    if (shallow) {
      newFlowPlotItemA = setField(newFlowPlotItemA, "flowPlotSubList", []);
      newFlowPlotItemB = setField(newFlowPlotItemB, "flowPlotSubList", []);
    }

    const isEqualAB = isEqual(newFlowPlotItemA, newFlowPlotItemB);

    if (flowPlotItemA.flowPlotSubList.length === 0 || shallow) {
      return isEqualAB;
    } else {
      if (!isEqualAB) {
        return isEqualAB;
      } else {
        logger.info(
          "areFlowPlotItemsEqual",
          "props",
          flowPlotItemA.id,
          "Checking if the sublists are equal:\n",
          "\nflowPlotItemA.flowPlotSubList:\n",
          flowPlotItemA.flowPlotSubList,
          "\nflowPlotItemB.flowPlotSubList:\n",
          flowPlotItemB.flowPlotSubList
        )();
        const areSubListsEqual = areFlowPlotListsEqual(
          flowPlotItemA.flowPlotSubList,
          flowPlotItemB.flowPlotSubList,
          exclude
        );
        logger.info(
          "areFlowPlotItemsEqual",
          "props",
          flowPlotItemA.id,
          "Are sublists equal?\n",
          "\nflowPlotItemA.flowPlotSubList:\n",
          flowPlotItemA.flowPlotSubList,
          "\nflowPlotItemB.flowPlotSubList:\n",
          flowPlotItemB.flowPlotSubList,
          areSubListsEqual
        )();
        return areSubListsEqual;
      }
    }
  }
}


/**
 * Given two arrays of FlowPlotItems, determine if they are equal.
 *
 * @param flowPlotListA - an array of FlowPlotItems to compare with flowPlotListB.
 * @param flowPlotListB - an array of FlowPlotItems to compare with flowPlotListA.
 * @returns whether or not they are equal.
 */
function areFlowPlotListsEqual(flowPlotListA: Array<FlowPlotItem>,
  flowPlotListB: Array<FlowPlotItem>, exclude: Array<keyof FlowPlotItem>): boolean {

  if (flowPlotListA.length !== flowPlotListB.length) {
    return false;
  } else {
    let areEqual = true;
    flowPlotListA.forEach((item, index) => {
      if (!areFlowPlotItemsEqual(
        flowPlotListA[index],
        flowPlotListB[index],
        exclude
      )) {
        areEqual = false;
      }
    });
    return areEqual;
  }
}

/**
 * Given an updated flowPlotList, call setFlowPlotList and update the flowPlotListRef.
 *
 * @param flowPlotList - the main list of all flow plots.
 * @param setFlowPlotList - the React state setter for the main list of FlowPlotItems.
 * @param flowPlotListRef - the main ref list of FlowPlotItems.
 */
export function updateFlowPlotList(
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): void {

  logger.info(
    "updateFlowPlotList",
    "flowPlotItems",
    "NA",
    "Updating flowPlotList to: ",
    flowPlotList
  )();
  flowPlotListRef.current = flowPlotList;
  setFlowPlotList(flowPlotList);
}


/**
 * Given an updated FlowPlotItem, update the list of FlowPlotItems and return this list.
 *
 * @param flowPlotItemToUpdate - the FlowPlotItem that has been updated.
 * @param flowPlotList - the main list of all flow plots.
 */
export function replaceItemInFlowPlotListTree(
  flowPlotItemToUpdate: FlowPlotItem,
  flowPlotList: Array<FlowPlotItem>
): Array<FlowPlotItem> {

  const newFlowPlotList = flowPlotList.map((flowPlotItem) => {
    return replaceItemInFlowPlotItemTree(flowPlotItemToUpdate, flowPlotItem)
  });
  logger.info(
    "replaceItemInFlowPlotListTree",
    "flowPlotItems",
    flowPlotItemToUpdate.id,
    "newFlowPlotList: ",
    newFlowPlotList
  )();
  return newFlowPlotList;
}


/**
 * Given an updated FlowPlotItem `flowPlotItemToUpdate`, update the FlowPlotItem of the current
 * iteration `flowPlotItem`.
 *
 * Iterate recursively through the tree of FlowPlotItems.
 * When we reach the flowPlotItem that we want to update, we update its flowPlotSubList.
 *
 * @param flowPlotItemToUpdate - the FlowPlotItem we are updating.
 * @param flowPlotItem - the FlowPlotItem from the current iteration through
 *  the tree of flowPlotList.
 */
function replaceItemInFlowPlotItemTree(
  flowPlotItemToUpdate: FlowPlotItem,
  flowPlotItem: FlowPlotItem
): FlowPlotItem {

  if (flowPlotItemToUpdate.id === flowPlotItem.id) {
    logger.info(
      "replaceItemInFlowPlotItemTree",
      "flowPlotItems",
      flowPlotItemToUpdate.id,
      "flowPlotItem: ",
      flowPlotItem,
      "flowPlotItemToUpdate: ",
      flowPlotItemToUpdate
    )();
    logger.info(
      "replaceItemInFlowPlotItemTree",
      "flowPlotItems",
      flowPlotItemToUpdate.id,
      "flowPlotSubList: ",
      flowPlotItemToUpdate.flowPlotSubList
    )();
    const newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      flowPlotSubList: flowPlotItemToUpdate.flowPlotSubList,
      channelX: flowPlotItemToUpdate.channelX,
      channelY: flowPlotItemToUpdate.channelY,
      x: flowPlotItemToUpdate.x,
      y: flowPlotItemToUpdate.y,
      topLayer: flowPlotItemToUpdate.topLayer,
      quadrantInfo: flowPlotItemToUpdate.quadrantInfo,
      density: flowPlotItemToUpdate.density,
      maxDensity: flowPlotItemToUpdate.maxDensity,
      minDensity: flowPlotItemToUpdate.minDensity,
      xUnbounded: flowPlotItemToUpdate.xUnbounded,
      yUnbounded: flowPlotItemToUpdate.yUnbounded
    });

    logger.info(
      "replaceItemInFlowPlotItemTree",
      "flowPlotItems",
      flowPlotItemToUpdate.id,
      "newFlowPlotItem: ",
      newFlowPlotItem
    )();
    return newFlowPlotItem;
  } else if (flowPlotItem.flowPlotSubList.length === 0) {
    logger.info(
      "replaceItemInFlowPlotItemTree",
      "flowPlotItems",
      flowPlotItemToUpdate.id,
      "flowPlotItem: ",
      flowPlotItem
    )();
    return flowPlotItem;
  } else {
    const newFlowPlotSubList = replaceItemInFlowPlotListTree(
      flowPlotItemToUpdate, flowPlotItem.flowPlotSubList
    );
    const newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      flowPlotSubList: newFlowPlotSubList
    });
    return newFlowPlotItem;
  }
}


/**
 * Given a FlowPlotItem to delete, update the list of FlowPlotItems and return this list.
 *
 * @param flowPlotItemToDelete - the FlowPlotItem we want to delete.
 * @param flowPlotList - the main list of all flow plots.
 */
function deleteItemInFlowPlotListTree(flowPlotItemToDelete: FlowPlotItem,
  flowPlotList: Array<FlowPlotItem>):
  Array<FlowPlotItem> {

  const [isInList, itemIndex] = flowPlotItemInList(flowPlotItemToDelete, flowPlotList);

  logger.info(
    "deleteItemInFlowPlotListTree",
    "flowPlotItems",
    flowPlotItemToDelete.id,
    "flowPlotItemToDelete is in the given flowPlotList?: ",
    isInList
  )();
  let newFlowPlotList = [];
  if (isInList) {
    newFlowPlotList = [...flowPlotList];
    newFlowPlotList.splice(itemIndex, 1);
    logger.info(
      "deleteItemInFlowPlotListTree",
      "flowPlotItems",
      flowPlotItemToDelete.id,
      "newFlowPlotList: ",
      newFlowPlotList
    )();
  } else {
    newFlowPlotList = flowPlotList.map((flowPlotItem) => {
      return deleteItemInFlowPlotItemTree(flowPlotItemToDelete, flowPlotItem)
    });
  }
  logger.info(
    "deleteItemInFlowPlotListTree",
    "flowPlotItems",
    flowPlotItemToDelete.id,
    "newFlowPlotList: ",
    newFlowPlotList
  )();
  return newFlowPlotList;
}


/**
 * Given a FlowPlotItem `flowPlotItemToDelete` we want to delete, update the FlowPlotItem of the
 * current iteration `flowPlotItem`.
 *
 * Iterate recursively through the tree of FlowPlotItems.
 *
 * @param flowPlotItemToDelete - the FlowPlotItem we want to delete.
 * @param flowPlotItem - the FlowPlotItem from the current iteration through
 *  the tree of flowPlotList.
 */
export function deleteItemInFlowPlotItemTree(flowPlotItemToDelete: FlowPlotItem,
  flowPlotItem: FlowPlotItem): FlowPlotItem {

  if (flowPlotItem.flowPlotSubList.length === 0) {
    logger.info(
      "deleteItemInFlowPlotItemTree",
      "flowPlotItems",
      flowPlotItemToDelete.id,
      "current flowPlotItem: ",
      flowPlotItem
    )();
    return flowPlotItem;
  } else {
    const newFlowPlotSubList = deleteItemInFlowPlotListTree(
      flowPlotItemToDelete, flowPlotItem.flowPlotSubList
    );
    const newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      flowPlotSubList: newFlowPlotSubList
    });
    return newFlowPlotItem;
  }
}


/**
 * Get a FlowPlotItem from a list of FlowPlotItems by id.
 *
 * Iterate recursively through the tree of FlowPlotItems.
 * When we reach the flowPlotItem that matches the id, we return that flowPlotItem.
 *
 * @param id - the unique id for the flowPlotItem we are looking for.
 * @param flowPlotList - a list of FlowPlotItems.
 * @param flowPlotItem - the FlowPlotItem from the current iteration through
 *  the tree of flowPlotList.
 * @returns an array where the first element indicates whether or not the FlowPlotItem has been
 *  found, and the second element is the FlowPlotItem.
 */
function getFlowPlotItemById(id: string, flowPlotList: Array<FlowPlotItem>,
  flowPlotItem: FlowPlotItem = new FlowPlotItem({})):
  [boolean, FlowPlotItem] {

  if (flowPlotItem.id === id) {
    return [true, flowPlotItem];
  } else if (flowPlotList.length === 0) {
    return [false, flowPlotItem];
  } else {
    let foundItem = false;
    for (const item of flowPlotList) {
      [foundItem, flowPlotItem] = getFlowPlotItemById(id, item.flowPlotSubList, item);
      if (foundItem) { break; }
    }
    return [foundItem, flowPlotItem];
  }
}


/**
 * Update the data in the flowPlotItem.
 *
 * If reloadFlowPlotList is true, then we will reload the data from the server.
 * If reloadFlowPlotList is false, then we will check to see if this flow plot already exists in
 * the list. For example, when creating a new flow plot, the default is to create a plot of the
 * first and second channels. If this plot has already been plotted, then we won't make another
 * call to the server.
 *
 * @param flowPlotItemToUpdate - the FlowPlotItem that we want to get new data for.
 * @param fileName - the name of the *.fcs file to load data from.
 * @param flowPlotList - the main list of FlowPlotItems.
 * @param flowData - the FlowData objecting containing the data.
 * @param reloadFlowPlotList - whether or not to reload the data from the server.
 * @returns the updated FlowPlotItem.
 */
async function updateFlowPlotItemData(
  flowPlotItemToUpdate: FlowPlotItem,
  flowPlotList: Array<FlowPlotItem>,
  flowData: FlowData,
  reloadFlowPlotList = false
): Promise<FlowPlotItem> {

  let x = [0];
  let y = [0];
  let density = [0];
  let maxDensity = 1;
  let minDensity = 0;

  let channelX = matchChannel(flowPlotItemToUpdate.channelX, flowData.channels);
  let channelY = matchChannel(flowPlotItemToUpdate.channelY, flowData.channels);

  if (channelX === null) {
    channelX = flowData.channels[0];
  }
  if (channelY === null) {
    channelY = flowData.channels[1];
  }

  let [isInList, prevFlowPlotItem] = [false, new FlowPlotItem({})];
  if (!reloadFlowPlotList) {
    [isInList, prevFlowPlotItem] = channelDataInList(
      channelX, channelY, flowPlotList, flowPlotItemToUpdate.id
    );
  }
  logger.info(
    "updateFlowPlotItemData",
    "flowPlotItems",
    flowPlotItemToUpdate.id,
    "isInList?: ",
    isInList
  )();
  if (isInList) {
    x = prevFlowPlotItem.x;
    y = prevFlowPlotItem.y;
    density = prevFlowPlotItem.density;
    maxDensity = prevFlowPlotItem.maxDensity;
    minDensity = prevFlowPlotItem.minDensity;
  } else {
    logger.info(
      "updateFlowPlotItemData",
      "flowPlotItems",
      flowPlotItemToUpdate.id,
      "getting plot data from backend: ",
      flowPlotItemToUpdate
    )();
    [x, y, density, maxDensity, minDensity] = await getPlotData(
      flowData.events[channelX], flowData.events[channelY], channelX, channelY,
      flowPlotItemToUpdate.indices, Math.min(...flowData.events[channelX]),
      Math.max(...flowData.events[channelX]), Math.min(...flowData.events[channelY]),
      Math.max(...flowData.events[channelY])
    );
  }

  const newFlowPlotItem = new FlowPlotItem({
    ...flowPlotItemToUpdate.toObject(),
    channelX: channelX,
    channelY: channelY,
    x: x,
    y: y,
    density: density,
    maxDensity: maxDensity,
    minDensity: minDensity,
    clusters: flowData.events.cluster,
    layers: flowData.layers,
    xUnbounded: flowData.events[channelX],
    yUnbounded: flowData.events[channelY]
  });

  return newFlowPlotItem;
};


/**
 * Update the data in the flowPlotItem and all of its sub-items recursively.
 *
 * 1. A call is made to updateFlowPlotItemData, which will call the server to get new data.
 * 2. If  ( the flowPlotItem has no items in the flowPlotSubList ):
 *      return flowPlotItem
 *    Else :
 *      iterate through the flowPlotSubList recursively
 *      return flowPlotItem
 *
 * @param flowPlotItem - the FlowPlotItem that we want to get new data for.
 * @param flowPlotList - the main list of FlowPlotItems.
 * @param flowData - the FlowData objecting containing the data.
 * @param reloadFlowPlotList - whether or not to reload the data from the server.
 * @returns the updated FlowPlotItem.
 */
async function updateFlowPlotItemDataTree(flowPlotItem: FlowPlotItem,
  flowPlotList: Array<FlowPlotItem>, flowData: FlowData, reloadFlowPlotList: boolean,
  parentFlowPlotItem?: FlowPlotItem):
  Promise<FlowPlotItem> {

  logger.info(
    "updateFlowPlotItemDataTree",
    "flowPlotItems",
    flowPlotItem.id,
    "flowPlotList: ",
    flowPlotList
  )();


  let newFlowPlotItem = new FlowPlotItem({});
  if ((parentFlowPlotItem === undefined) ||
    (parentFlowPlotItem !== undefined && parentFlowPlotItem.quadrantInfo === undefined)) {
    logger.info(
      "updateFlowPlotItemDataTree",
      "flowPlotItems",
      flowPlotItem.id,
      "parentFlowPlotItem undefined, flowPlotItem: ",
      flowPlotItem
    )();
    newFlowPlotItem = await updateFlowPlotItemData(flowPlotItem, flowPlotList,
      flowData, reloadFlowPlotList);
  } else {
    const quadrantInfo = new QuadrantInfo({
      ...parentFlowPlotItem.quadrantInfo!,
      boundsHeight: parentFlowPlotItem.quadrantInfo!.boundsHeight,
      boundsWidth: parentFlowPlotItem.quadrantInfo!.boundsWidth,
      quadrantId: flowPlotItem.quadrantInfo!.parentQuadrantId,
      margin: flowPlotItem.margin
    })

    logger.info(
      "updateFlowPlotItemDataTree",
      "flowPlotItems",
      flowPlotItem.id,
      "parentFlowPlotItem: ",
      parentFlowPlotItem
    )();
    logger.info(
      "updateFlowPlotItemDataTree",
      "flowPlotItems",
      flowPlotItem.id,
      "parentQuadrantId: ",
      flowPlotItem.quadrantInfo!.parentQuadrantId
    )();

    const [ , , , clusters, layers, indices] = await getDataInQuadrant(
      parentFlowPlotItem.x, parentFlowPlotItem.y, parentFlowPlotItem.density,
      parentFlowPlotItem.clusters, quadrantInfo,
      parentFlowPlotItem.xScale, parentFlowPlotItem.yScale
    );

    let channelX = matchChannel(flowPlotItem.channelX, flowData.channels);
    let channelY = matchChannel(flowPlotItem.channelY, flowData.channels);

    if (channelX == null) {
      channelX = flowData.channels[0];
    }
    if (channelY == null) {
      channelY = flowData.channels[1];
    }

    const [x, y, density, maxDensity, minDensity] = await getPlotData(
      flowData.events[channelX], flowData.events[channelY], channelX, channelY, indices,
      Math.min(...flowData.events[channelX]), Math.max(...flowData.events[channelX]),
      Math.min(...flowData.events[channelY]), Math.max(...flowData.events[channelY])
    );

    newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      x: x,
      y: y,
      density: density,
      maxDensity: maxDensity,
      minDensity: minDensity,
      indices: indices,
      clusters: clusters,
      layers: layers,
      channelX: channelX,
      channelY: channelY
    });
  }

  logger.info(
    "updateFlowPlotItemDataTree",
    "flowPlotItems",
    flowPlotItem.id,
    "newFlowPlotItem: ",
    newFlowPlotItem
  )();

  if (newFlowPlotItem.flowPlotSubList.length === 0) {
    return newFlowPlotItem;
  } else {
    const newFlowPlotSubList = await updateFlowPlotListDataTree(newFlowPlotItem.flowPlotSubList,
      flowData, reloadFlowPlotList, newFlowPlotItem
    );
    newFlowPlotItem.flowPlotSubList = newFlowPlotSubList;
    return newFlowPlotItem;
  }
}

/**
 * Update the data in the flowPlotList and all of its sub-items recursively.
 *
 * @param flowPlotList - a list of FlowPlotItems.
 * @param fileName - the name of the *.fcs file to load data from.
 * @param flowData - the FlowData objecting containing the data.
 * @param reloadFlowPlotList - whether or not to reload all the data from the server.
 * @returns the updated list of FlowPlotItems.
 */
async function updateFlowPlotListDataTree(flowPlotList: Array<FlowPlotItem>,
  flowData: FlowData, reloadFlowPlotList: boolean, parentFlowPlotItem?: FlowPlotItem):
  Promise<Array<FlowPlotItem>> {
  const newFlowPlotList = await Promise.all(flowPlotList.map((item) => {
    const newItem = updateFlowPlotItemDataTree(item, flowPlotList, flowData,
      reloadFlowPlotList, parentFlowPlotItem);
    return newItem;
  }));

  return newFlowPlotList;
}


/**
 * Check if the flowPlotItem is in the flowPlotList already.
 *
 * @param flowPlotItem - the FlowPlotItem we are interested in.
 * @param flowPlotList - a list of FlowPlotItems.
 * @returns whether or not the flowPlotItem is in the list, and its index.
 */
function flowPlotItemInList(
  flowPlotItem: FlowPlotItem, flowPlotList: Array<FlowPlotItem>, depth = "shallow"
): [boolean, number] {

  logger.info(
    "flowPlotItemInList",
    "flowPlotItems",
    flowPlotItem.id,
    "flowPlotList: ",
    flowPlotList
  )();

  let isInList = false;
  let itemIndex = 0;

  if (flowPlotList.length > 0) {
    if (depth === "shallow") {
      flowPlotList.forEach((item, index) => {
        if (flowPlotItem.id === item.id) {
          isInList = true;
          itemIndex = index;
        }
      });
    }
  }
  return [isInList, itemIndex];
}



/**
 * Check if the data for the given channels is already in the flowPlotList.
 *
 * @param channelX - the name of the channel on the x-axis.
 * @param channelY - the name of the channel on the y-axis.
 * @param flowPlotList - a list of FlowPlotItems.
 * @param id - the unique uuid of the flowPlotItem.
 * @returns whether or not the data is in the list, and the previous flowPlotItem.
 */
function channelDataInList(
  channelX: string, channelY: string, flowPlotList: Array<FlowPlotItem>, id: string
): [boolean, FlowPlotItem] {
  let isInList = false;
  let prevFlowPlotItem = new FlowPlotItem({});
  flowPlotList.forEach((flowPlotItem) => {
    if (flowPlotItem.channelX === channelX && flowPlotItem.channelY === channelY
      && flowPlotItem.id !== id) {
      isInList = true;
      prevFlowPlotItem = flowPlotItem;
    }
  });
  return [isInList, prevFlowPlotItem];
}



export {
  updateFlowPlotItemData, updateFlowPlotListDataTree,
  getFlowPlotItemById, deleteItemInFlowPlotListTree,
  flowPlotItemInList, areFlowPlotItemsEqual, areFlowPlotListsEqual, FlowPlotItem
};
